/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-6fpd9u css-11qru5s pb--60 pt--60" name={"o5ad4v6oqt"} layout={"l1"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/19880/c0b972949ca94532bb3275568d5d524d_s=3000x_.jpg);
    }
  
background-position: 50% 29%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold;\"><span style=\"color: var(--black);\"><br><br><br><br><br><br></span><br><br><br><br></span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080,"backgroundColor":"rgba(255, 255, 255, 1)"}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Příprava bytu k pronájmu v Kroměříži</span>"}>
              </Subtitle>

              <Text className="text-box fs--14" content={"- Kompletní výmalba vč. škrábání a opravy stěn&nbsp;<br>- Dodávka a montáž plovoucí podlahy a PVC\n<br>- Nátěr radiátorů, teplovod. potrubí, zárubní, dveří<br>- Výměna zásuvek, vypínačů, vod. baterií, WC, sporáku<br>- Oprava žaluzií a kompletní úklid - <span style=\"font-weight: bold;\">připraveno k nastěhování</span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19880/7d7e655144394da0930fc6ecb006cfdf_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/7d7e655144394da0930fc6ecb006cfdf_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19880/7d7e655144394da0930fc6ecb006cfdf_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19880/7d7e655144394da0930fc6ecb006cfdf_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19880/7d7e655144394da0930fc6ecb006cfdf_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19880/7d7e655144394da0930fc6ecb006cfdf_s=2000x_.JPG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/c2bf0265ba7e4df38b0189da69af5bf4_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/c2bf0265ba7e4df38b0189da69af5bf4_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19880/c2bf0265ba7e4df38b0189da69af5bf4_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19880/c2bf0265ba7e4df38b0189da69af5bf4_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19880/c2bf0265ba7e4df38b0189da69af5bf4_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19880/c2bf0265ba7e4df38b0189da69af5bf4_s=2000x_.JPG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/8329ff27a2cb431fa104cf454311881f_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/8329ff27a2cb431fa104cf454311881f_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19880/8329ff27a2cb431fa104cf454311881f_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19880/8329ff27a2cb431fa104cf454311881f_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19880/8329ff27a2cb431fa104cf454311881f_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19880/8329ff27a2cb431fa104cf454311881f_s=2000x_.JPG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/9e137126b527469bb931f6834bfe2fcd_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/9e137126b527469bb931f6834bfe2fcd_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19880/9e137126b527469bb931f6834bfe2fcd_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19880/9e137126b527469bb931f6834bfe2fcd_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19880/9e137126b527469bb931f6834bfe2fcd_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19880/9e137126b527469bb931f6834bfe2fcd_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Subtitle className="subtitle-box" content={"<br><br>"}>
              </Subtitle>

              <Button className="btn-box btn-box--center" url={"https://www.facebook.com/profile.php?id=61550787952921"} href={"https://www.facebook.com/profile.php?id=61550787952921"} style={{"backgroundColor":"rgba(51,20,199,1)"}} content={"Navštivte nás na Facebooku"}>
              </Button>

              <Image src={"https://cdn.swbpg.com/t/19880/d654c6e0c73c44f892ed3c34755adad3_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/d654c6e0c73c44f892ed3c34755adad3_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19880/d654c6e0c73c44f892ed3c34755adad3_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19880/d654c6e0c73c44f892ed3c34755adad3_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19880/d654c6e0c73c44f892ed3c34755adad3_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19880/d654c6e0c73c44f892ed3c34755adad3_s=2000x_.JPG 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/a4f4f6e1d0dc4e77b487db2f5c9dab64_s=860x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/a4f4f6e1d0dc4e77b487db2f5c9dab64_s=350x_.JPG 350w, https://cdn.swbpg.com/t/19880/a4f4f6e1d0dc4e77b487db2f5c9dab64_s=660x_.JPG 660w, https://cdn.swbpg.com/t/19880/a4f4f6e1d0dc4e77b487db2f5c9dab64_s=860x_.JPG 860w, https://cdn.swbpg.com/t/19880/a4f4f6e1d0dc4e77b487db2f5c9dab64_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/19880/a4f4f6e1d0dc4e77b487db2f5c9dab64_s=2000x_.JPG 2000w"}>
              </Image>

              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Revitalizace dětského pokoje v Kroměříži:</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"- Odstranění původní podlahové krytiny<br>- Aplikace samonivelační stěrky<br>- Montáž kobercových čtverců<br>- Malba na bílo<br>- Obložení stěn<br>- Montáž nábytku -<span style=\"font-weight: bold;\"> připraveno k nastěhování</span>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19880/913edafcf04c464aa044a4c7dea01bd0_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":490}} srcSet={"https://cdn.swbpg.com/t/19880/913edafcf04c464aa044a4c7dea01bd0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/913edafcf04c464aa044a4c7dea01bd0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/913edafcf04c464aa044a4c7dea01bd0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/913edafcf04c464aa044a4c7dea01bd0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/913edafcf04c464aa044a4c7dea01bd0_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/8932dbe6d09b452eab856d38065561ff_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":485}} srcSet={"https://cdn.swbpg.com/t/19880/8932dbe6d09b452eab856d38065561ff_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/8932dbe6d09b452eab856d38065561ff_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/8932dbe6d09b452eab856d38065561ff_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/8932dbe6d09b452eab856d38065561ff_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/8932dbe6d09b452eab856d38065561ff_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box fs--18" content={"<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080,"backgroundColor":"rgba(255, 255, 255, 1)"}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Malířské práce:</span><span style=\"font-weight: bold;\"><br><br></span>"}>
              </Title>

              <Text className="text-box text-box--center fs--18" content={"Nabízíme kompletní malířské práce včetně zakrytí podlah, oken, veškerého nábytku, vybavení domácnosti a závěrečného úklidu.<br><br><br><br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19880/e60e9708707c44edbae53c266081c4c2_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/19880/e60e9708707c44edbae53c266081c4c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/e60e9708707c44edbae53c266081c4c2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/e60e9708707c44edbae53c266081c4c2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/e60e9708707c44edbae53c266081c4c2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/e60e9708707c44edbae53c266081c4c2_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/202c8028f6694c8d83edcb22c2883e6b_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":536}} srcSet={"https://cdn.swbpg.com/t/19880/202c8028f6694c8d83edcb22c2883e6b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/202c8028f6694c8d83edcb22c2883e6b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/202c8028f6694c8d83edcb22c2883e6b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/202c8028f6694c8d83edcb22c2883e6b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/202c8028f6694c8d83edcb22c2883e6b_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"<br><br><br><br><br>"}>
              </Text>

              <Image className="pt--02" src={"https://cdn.swbpg.com/t/19880/7d4667793db841a08874d44948d6031f_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":590}} srcSet={"https://cdn.swbpg.com/t/19880/7d4667793db841a08874d44948d6031f_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/19880/7d4667793db841a08874d44948d6031f_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/19880/7d4667793db841a08874d44948d6031f_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/19880/7d4667793db841a08874d44948d6031f_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/19880/7d4667793db841a08874d44948d6031f_s=2000x_.jpeg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/d921bc2dcdce4c8bbe352339b131bf35_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":915}} srcSet={"https://cdn.swbpg.com/t/19880/d921bc2dcdce4c8bbe352339b131bf35_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/d921bc2dcdce4c8bbe352339b131bf35_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/d921bc2dcdce4c8bbe352339b131bf35_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/d921bc2dcdce4c8bbe352339b131bf35_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/d921bc2dcdce4c8bbe352339b131bf35_s=2000x_.jpg 2000w"}>
              </Image>

              <Button className="btn-box btn-box--center" url={"https://www.facebook.com/profile.php?id=61550787952921"} href={"https://www.facebook.com/profile.php?id=61550787952921"} style={{"backgroundColor":"rgba(58,17,255,1)"}} content={"Navštivte nás na Facebooku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Podlahářské práce:<br><br></span>"}>
              </Title>

              <Text className="text-box text-box--center fs--18" content={"Nabízíme kompletní podlahářské práce. Příprava podkladů, stěrkování, dodávka a montáž materiálu ( pvc, vinyl, koberce, kobercové čtverce, laminátové podlahy).<br><br>"}>
              </Text>

              <Image alt={"Nivelace podlahy"} src={"https://cdn.swbpg.com/t/19880/6b09d6c85cc1432b8886d1260d619562_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":744}} srcSet={"https://cdn.swbpg.com/t/19880/6b09d6c85cc1432b8886d1260d619562_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/6b09d6c85cc1432b8886d1260d619562_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/6b09d6c85cc1432b8886d1260d619562_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/6b09d6c85cc1432b8886d1260d619562_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/6b09d6c85cc1432b8886d1260d619562_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/e0ee1c72c9f448acb00101cafed25dea_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":594}} srcSet={"https://cdn.swbpg.com/t/19880/e0ee1c72c9f448acb00101cafed25dea_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/e0ee1c72c9f448acb00101cafed25dea_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/e0ee1c72c9f448acb00101cafed25dea_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/e0ee1c72c9f448acb00101cafed25dea_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/e0ee1c72c9f448acb00101cafed25dea_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/6509ea10af4148ec93af35ce0b1fe6ba_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":572}} srcSet={"https://cdn.swbpg.com/t/19880/6509ea10af4148ec93af35ce0b1fe6ba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/6509ea10af4148ec93af35ce0b1fe6ba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/6509ea10af4148ec93af35ce0b1fe6ba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/6509ea10af4148ec93af35ce0b1fe6ba_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/6509ea10af4148ec93af35ce0b1fe6ba_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/78d2babaa24f4c4382b3cfbf3386ba9e_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":572}} srcSet={"https://cdn.swbpg.com/t/19880/78d2babaa24f4c4382b3cfbf3386ba9e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/78d2babaa24f4c4382b3cfbf3386ba9e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/78d2babaa24f4c4382b3cfbf3386ba9e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/78d2babaa24f4c4382b3cfbf3386ba9e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/78d2babaa24f4c4382b3cfbf3386ba9e_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--16" content={"<br><br><br><br><br><br><br>"}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19880/1178498061b74585b410202deb820999_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":359}} srcSet={"https://cdn.swbpg.com/t/19880/1178498061b74585b410202deb820999_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/1178498061b74585b410202deb820999_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/1178498061b74585b410202deb820999_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/1178498061b74585b410202deb820999_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/c1af093a7838434fa2d02a95969600fe_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":359}} srcSet={"https://cdn.swbpg.com/t/19880/c1af093a7838434fa2d02a95969600fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/c1af093a7838434fa2d02a95969600fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/c1af093a7838434fa2d02a95969600fe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/c1af093a7838434fa2d02a95969600fe_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19880/90919f1f72ad453593df4b7bf0ec91aa_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19880/90919f1f72ad453593df4b7bf0ec91aa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/90919f1f72ad453593df4b7bf0ec91aa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/90919f1f72ad453593df4b7bf0ec91aa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/90919f1f72ad453593df4b7bf0ec91aa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/90919f1f72ad453593df4b7bf0ec91aa_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Vyklízení/úklid nemovitostí:<br></span>"}>
              </Title>

              <Text className="text-box text-box--center fs--18" content={"Nabízíme kompletní vyklízení a úklid nemovitostí."}>
              </Text>

              <Image src={"https://cdn.swbpg.com/t/19880/4a1388537c064347815b41925978ba6d_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":375}} srcSet={"https://cdn.swbpg.com/t/19880/4a1388537c064347815b41925978ba6d_s=350x_.jpg 350w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/2e6ebbe7139d4c35b6e1b643c7f41d64_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":375}} srcSet={"https://cdn.swbpg.com/t/19880/2e6ebbe7139d4c35b6e1b643c7f41d64_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/2e6ebbe7139d4c35b6e1b643c7f41d64_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/2e6ebbe7139d4c35b6e1b643c7f41d64_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/2e6ebbe7139d4c35b6e1b643c7f41d64_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/2e6ebbe7139d4c35b6e1b643c7f41d64_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box fs--18" content={"<br><br><br>"}>
              </Text>

              <Image className="pb--0 pt--02" src={"https://cdn.swbpg.com/t/19880/595b68e99cab46f4a1e373201f421441_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":379}} srcSet={"https://cdn.swbpg.com/t/19880/595b68e99cab46f4a1e373201f421441_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/595b68e99cab46f4a1e373201f421441_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/595b68e99cab46f4a1e373201f421441_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/595b68e99cab46f4a1e373201f421441_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/595b68e99cab46f4a1e373201f421441_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/fe593c12385542c2be5f40ebe0646b73_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":376}} srcSet={"https://cdn.swbpg.com/t/19880/fe593c12385542c2be5f40ebe0646b73_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/fe593c12385542c2be5f40ebe0646b73_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/fe593c12385542c2be5f40ebe0646b73_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/fe593c12385542c2be5f40ebe0646b73_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/94e7bebd18444d8d95c3bd9cb1c1f4d8_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":376}} srcSet={"https://cdn.swbpg.com/t/19880/94e7bebd18444d8d95c3bd9cb1c1f4d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/94e7bebd18444d8d95c3bd9cb1c1f4d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/94e7bebd18444d8d95c3bd9cb1c1f4d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/94e7bebd18444d8d95c3bd9cb1c1f4d8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/94e7bebd18444d8d95c3bd9cb1c1f4d8_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hhvxgvi23oc"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--30" content={"<span style=\"font-weight: bold;\">Zahradnické práce:<br><br><br></span>"}>
              </Title>

              <Text className="text-box text-box--center fs--18" content={"Provádíme sečení a vertikutaci trávníku, odstranění a likvidaci náletových dřevin, údržbu a realizaci zahrad. Provádíme realizaci okrasných záhonů i záhonů pro pěstovaní ovoce/zeleniny.<br><br>"}>
              </Text>

              <Text className="text-box text-box--center fs--16" content={"Odstranění náletových dřevin"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19880/b38c96be164d4033a8b68ffba6b0005d_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19880/b38c96be164d4033a8b68ffba6b0005d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/b38c96be164d4033a8b68ffba6b0005d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/b38c96be164d4033a8b68ffba6b0005d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/b38c96be164d4033a8b68ffba6b0005d_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19880/9ffd224614e54c7c88c6377e2a1d4647_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19880/9ffd224614e54c7c88c6377e2a1d4647_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/9ffd224614e54c7c88c6377e2a1d4647_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/9ffd224614e54c7c88c6377e2a1d4647_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/9ffd224614e54c7c88c6377e2a1d4647_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box text-box--center fs--16" content={"Zakládání trávníku"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19880/20d041d8534a44489e4cdd5479568f30_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19880/20d041d8534a44489e4cdd5479568f30_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/20d041d8534a44489e4cdd5479568f30_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/20d041d8534a44489e4cdd5479568f30_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/20d041d8534a44489e4cdd5479568f30_s=1400x_.jpg 1400w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/979bd0f3fb58463e947aa404791fc913_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":365}} srcSet={"https://cdn.swbpg.com/t/19880/979bd0f3fb58463e947aa404791fc913_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/979bd0f3fb58463e947aa404791fc913_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/979bd0f3fb58463e947aa404791fc913_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/979bd0f3fb58463e947aa404791fc913_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/979bd0f3fb58463e947aa404791fc913_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center" content={"Vertikutace trávníku"}>
              </Text>

              <Image alt={""} src={"https://cdn.swbpg.com/t/19880/e6e45765905344ea99176d2e8f1006be_s=350x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/19880/e6e45765905344ea99176d2e8f1006be_s=350x_.jpg 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box text-box--center fs--16" content={"Jahodníkový záhon."}>
              </Text>

              <Image className="pt--02" src={"https://cdn.swbpg.com/t/19880/f460ab1cfeae4bd7bbaf94fde0404edc_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":381}} srcSet={"https://cdn.swbpg.com/t/19880/f460ab1cfeae4bd7bbaf94fde0404edc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/f460ab1cfeae4bd7bbaf94fde0404edc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/f460ab1cfeae4bd7bbaf94fde0404edc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/f460ab1cfeae4bd7bbaf94fde0404edc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/f460ab1cfeae4bd7bbaf94fde0404edc_s=2000x_.jpg 2000w"}>
              </Image>

              <Image className="pt--02" src={"https://cdn.swbpg.com/t/19880/b0ab0bab08d64ea3938720cb65e69141_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":375}} srcSet={"https://cdn.swbpg.com/t/19880/b0ab0bab08d64ea3938720cb65e69141_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/b0ab0bab08d64ea3938720cb65e69141_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/b0ab0bab08d64ea3938720cb65e69141_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/b0ab0bab08d64ea3938720cb65e69141_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/b0ab0bab08d64ea3938720cb65e69141_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--16" content={"Úklid zahrady"}>
              </Text>

              <Image className="pt--02" src={"https://cdn.swbpg.com/t/19880/d2f55858a91b436a800bc67ea9d09a8c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":458}} srcSet={"https://cdn.swbpg.com/t/19880/d2f55858a91b436a800bc67ea9d09a8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/d2f55858a91b436a800bc67ea9d09a8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/d2f55858a91b436a800bc67ea9d09a8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/d2f55858a91b436a800bc67ea9d09a8c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/d2f55858a91b436a800bc67ea9d09a8c_s=2000x_.jpg 2000w"}>
              </Image>

              <Image src={"https://cdn.swbpg.com/t/19880/fd77fef8218d47b083cf50cd247855f9_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":458}} srcSet={"https://cdn.swbpg.com/t/19880/fd77fef8218d47b083cf50cd247855f9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19880/fd77fef8218d47b083cf50cd247855f9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19880/fd77fef8218d47b083cf50cd247855f9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19880/fd77fef8218d47b083cf50cd247855f9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19880/fd77fef8218d47b083cf50cd247855f9_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--center fs--18" content={"Další foto naleznete na naší facebookové stránce a na instagramu&nbsp;"}>
              </Text>

              <Button className="btn-box btn-box--center" url={"https://www.facebook.com/profile.php?id=61550787952921"} href={"https://www.facebook.com/profile.php?id=61550787952921"} style={{"backgroundColor":"rgba(23,41,234,1)"}} content={"Navštívit Facebook"}>
              </Button>

              <Button className="btn-box btn-box--center" url={"https://www.instagram.com/homestagingkm/"} href={"https://www.instagram.com/homestagingkm/"} style={{"backgroundColor":"rgba(234,146,23,1)"}} content={"Navštívit Instagram"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"rgba(11,0,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"color: var(--color-dominant);\">Slavomír Chalupa</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">+420 606 025 400\n<br>+420 724 952 477\n<br>IG: @homestagingkm<br>homestagingkm@gmail.com\n<br>IČ: 42603021<br></span><span style=\"color: var(--color-dominant);\"><br></span>Vyklízení Kroměříž, vyklízecí práce Kroměříž, vyklízení nemovitostí Kroměříž, vyklízení bytů Kroměříž, malování Kroměříž, malířské práce Kroměříž, malíři pokojů Kroměříž, maliri pokoju kromeriz, malování pokojů Kroměříž, podlahy Kroměříž, podlahářské práce Kroměříž, podlahář Kroměříž, podlahar Kromeriz, podlaháři Kroměříž, zahradnické práce Kroměříž, údržba zahrad Kroměříž, zahradník Kroměříž<span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":495}} content={"<span style=\"color: var(--color-dominant);\">.</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}