/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, ContactForm, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-s79cop --style2 --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19880/fc6261f90c164e69850b1f1c6eb7a09f_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--48" style={{"maxWidth":1276}} content={"<span style=\"color: white\"><br></span><span style=\"color: white\"><span style=\"font-weight: bold;\"><br></span><br><br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"l8lca1wp37p"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontaktujte nás"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 mt--10" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":false,"placeholder":"Zadejte Jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Napište nám","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"w5xf1fkp31"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"rgba(0,25,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"color: var(--color-dominant);\">Slavomír Chalupa</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--white);\">+420 606 025 400\n<br>+420 724 952 477<br>IG: @homestagingkm<br>homestagingkm@gmail.com<br>IČ: 42603021<br><br></span>Vyklízení Kroměříž, vyklízecí práce Kroměříž, vyklízení nemovitostí Kroměříž, vyklízení bytů Kroměříž, malování Kroměříž, malířské práce Kroměříž, malíři pokojů Kroměříž, maliri pokoju kromeriz, malování pokojů Kroměříž, podlahy Kroměříž, podlahářské práce Kroměříž, podlahář Kroměříž, podlahar Kromeriz, podlaháři Kroměříž, zahradnické práce Kroměříž, údržba zahrad Kroměříž, zahradník Kroměříž<span style=\"color: var(--white);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":495}} content={"<br>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}